@import "./../../../global-styles";

#variantTable {
  margin: 3% 0;

  .react-bs-table-pagination {
    margin-bottom: 10px;

    .react-bs-table-sizePerPage-dropdown {
      margin-left: 10px;
    }
  }

  table {
    margin-bottom: 1%;
    width: 100%;
    border-collapse: collapse;
    min-width: 865px;

    thead {
      tr {
        background-color: $main-background-color-top;

        th {
          font-size: 12px;
          color: $main-color;

          & + th {
            border-left: 1px solid black;
          }
        }
      }
    }
    tbody {
      font-size: 13px;

      tr {
        text-align: center;

        &:nth-child(even) {
          background-color: rgb(240, 238, 238);
        }

        td {
          padding: 4px;

          & + td {
            border-left: 1px solid black;
          }
        }
      }
    }
  }
}
