body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: Arial, Helvetica, sans-serif, normal !important;
  margin: -10px 0 !important; }

#mainPage {
  margin: 0 auto;
  background-color: white; }
  #mainPage #loginContainer {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-animation-name: slide-login;
            animation-name: slide-login;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s; }
  #mainPage .loginOn {
    opacity: 0.3;
    pointer-events: none; }
  #mainPage .mainBody {
    padding: 0 1%; }
  #mainPage h1 {
    color: #250ef1;
    font-weight: normal;
    margin: 1% 0;
    font-size: 24px; }
  #mainPage #footer {
    text-align: center; }

@media screen and (min-width: 1211px) {
  #mainPage {
    width: 75%; } }

@media screen and (min-width: 901px) and (max-width: 1211px) {
  #mainPage {
    width: 85%; } }

@media screen and (max-width: 900px) {
  #mainPage {
    width: 99%; } }

@media screen and (min-width: 741px) {
  #mainPage #headerBody {
    min-height: calc(100vh - 68px); } }

@media screen and (max-width: 740px) {
  #mainPage #headerBody {
    min-height: calc(100vh - 78px); } }

@-webkit-keyframes slide-login {
  0% {
    left: 0%; }
  100% {
    left: 50%; } }

@keyframes slide-login {
  0% {
    left: 0%; }
  100% {
    left: 50%; } }

.generalHeader {
  background: -webkit-linear-gradient(#fff, #CCCCFF) !important;
  background: linear-gradient(#fff, #CCCCFF) !important; }

@media screen and (min-width: 701px) {
  #headerContainer {
    margin: 10px auto; }
    #headerContainer #imageLogin {
      padding-left: 5%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      #headerContainer #imageLogin img {
        width: 45%;
        padding: 0 0 1% 0; } }
    @media screen and (min-width: 701px) and (max-width: 1025px) {
      #headerContainer #imageLogin img {
        width: 75%; } }

@media screen and (min-width: 701px) {
      #headerContainer #imageLogin .login {
        min-width: 200px;
        margin: auto;
        text-align: center;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        #headerContainer #imageLogin .login .loginLink,
        #headerContainer #imageLogin .login .signupLink {
          cursor: pointer;
          outline: none;
          background-color: #CCCCFF;
          border: 0 none;
          border-radius: 10px;
          text-align: center;
          color: white;
          padding: NaN;
          font-size: 20px;
          text-decoration: none; }
    #headerContainer #topMenuBar {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      background-color: #CCCCFF; }
      #headerContainer #topMenuBar .iconLink img {
        width: 30px;
        margin: 5px auto 0;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
      #headerContainer #topMenuBar .link {
        position: relative;
        display: inline-block; }
        #headerContainer #topMenuBar .link * {
          outline: none;
          border: none;
          color: white;
          font-size: 17px;
          cursor: pointer;
          text-decoration: none; }
        #headerContainer #topMenuBar .link:hover .filtersDropdown {
          display: -webkit-flex;
          display: flex;
          z-index: 3; }
        #headerContainer #topMenuBar .link .filtersDropdown {
          display: none;
          -webkit-flex-direction: column;
                  flex-direction: column;
          position: absolute;
          text-align: start;
          border: 0.1px solid grey;
          border-radius: 0 0 0.5em 0.5em; }
          #headerContainer #topMenuBar .link .filtersDropdown :hover {
            background-color: #202931; }
          #headerContainer #topMenuBar .link .filtersDropdown .lastLink {
            border-radius: 0 0 0.5em 0.5em; }
          #headerContainer #topMenuBar .link .filtersDropdown * {
            padding: 5px;
            background-color: grey;
            font-size: 14px; }
        #headerContainer #topMenuBar .link #documentation {
          left: -30%;
          width: 202px; } }

@media screen and (max-width: 700px) {
  #headerContainer {
    display: none; } }

@media screen and (max-width: 700px) {
  .mobileHeaderContainer {
    margin: 10px auto; }
    .mobileHeaderContainer #logoAndButton {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      margin-bottom: 5px; }
      .mobileHeaderContainer #logoAndButton #imageContainer {
        width: 100%; }
        .mobileHeaderContainer #logoAndButton #imageContainer img {
          width: 80%; }
      .mobileHeaderContainer #logoAndButton #hamburger {
        background-color: #250ef1;
        outline: none;
        border: none;
        width: 45px;
        height: 45px;
        border-radius: 0.5em;
        -webkit-align-self: flex-end;
                align-self: flex-end;
        cursor: pointer;
        margin: auto; }
        .mobileHeaderContainer #logoAndButton #hamburger .burger {
          color: white;
          font-size: 24px; }
    .mobileHeaderContainer .mobileLogin {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-around;
              justify-content: space-around;
      padding: 10px 0; }
      .mobileHeaderContainer .mobileLogin .mobileLoginLink {
        cursor: pointer;
        width: 100px;
        height: 38px;
        outline: none;
        background-color: #250ef1;
        border: 0 none;
        border-radius: 10px;
        text-align: center;
        color: white;
        padding: 5px 0;
        font-size: 20px; }
    .mobileHeaderContainer #mobileMenu {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      text-align: center; }
      .mobileHeaderContainer #mobileMenu img {
        width: 20px;
        margin: 0 10px 0 -10px;
        padding: 0; }
      .mobileHeaderContainer #mobileMenu .menuOption {
        background-color: #CCCCFF;
        cursor: pointer; }
        .mobileHeaderContainer #mobileMenu .menuOption .moreOptions {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          background-color: grey; }
        .mobileHeaderContainer #mobileMenu .menuOption#removePadding {
          padding-bottom: 0; }
      .mobileHeaderContainer #mobileMenu * {
        text-decoration: none;
        color: white;
        font-size: 24px; }
      .mobileHeaderContainer #mobileMenu #homeLink,
      .mobileHeaderContainer #mobileMenu #documentationLink,
      .mobileHeaderContainer #mobileMenu #mapLink,
      .mobileHeaderContainer #mobileMenu #diseasesLink,
      .mobileHeaderContainer #mobileMenu #genesLink,
      .mobileHeaderContainer #mobileMenu #frequencyLink,
      .mobileHeaderContainer #mobileMenu #researchersLink {
        padding: 2% 0; } }

@media screen and (min-width: 701px) {
  .mobileHeaderContainer {
    display: none; } }

#footerContainer {
  /* If you want it in the center, put again the same widths as
  the main page and put text align
  width: 60%;
  text-align: center;
  */ }
  #footerContainer #links {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around; }
    #footerContainer #links * {
      text-decoration: none;
      color: #250ef1; }
  #footerContainer #copyright {
    margin-top: 5px;
    font-size: 12px;
    color: grey; }

@media screen and (min-width: 901px) {
  #disclaimerPage {
    width: 80%; } }

@media screen and (min-width: 901px) {
  #privacyPage {
    width: 80%; } }

#mapPage #mapContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center; }
  #mapPage #mapContainer .rsm-svg {
    width: 80%;
    background-color: #c3d2fa; }
    #mapPage #mapContainer .rsm-svg .rsm-geography {
      outline: none; }
  #mapPage #mapContainer #zoomButtons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    #mapPage #mapContainer #zoomButtons button {
      background-color: #250ef1;
      border: 1px solid #250ef1;
      color: white;
      outline: none;
      margin: 5px;
      cursor: pointer;
      width: 54px;
      height: 54px; }
    #mapPage #mapContainer #zoomButtons .resetButton {
      border-radius: 30%;
      font-size: 14px; }
    #mapPage #mapContainer #zoomButtons .zoom {
      border-radius: 50%;
      font-size: 25px; }

#mapPage h2 {
  text-align: center; }

#mapPage h4 {
  margin-top: 10px;
  text-align: center; }

#variantTable {
  margin: 3% 0; }
  #variantTable .react-bs-table-pagination {
    margin-bottom: 10px; }
    #variantTable .react-bs-table-pagination .react-bs-table-sizePerPage-dropdown {
      margin-left: 10px; }
  #variantTable table {
    margin-bottom: 1%;
    width: 100%;
    border-collapse: collapse;
    min-width: 865px; }
    #variantTable table thead tr {
      background-color: #CCCCFF; }
      #variantTable table thead tr th {
        font-size: 12px;
        color: #250ef1; }
        #variantTable table thead tr th + th {
          border-left: 1px solid black; }
    #variantTable table tbody {
      font-size: 13px; }
      #variantTable table tbody tr {
        text-align: center; }
        #variantTable table tbody tr:nth-child(even) {
          background-color: #f0eeee; }
        #variantTable table tbody tr td {
          padding: 4px; }
          #variantTable table tbody tr td + td {
            border-left: 1px solid black; }

#homePage {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  #homePage #textAndImages {
    display: -webkit-flex;
    display: flex; }
    #homePage #textAndImages h4 {
      background-color: #250ef1;
      color: white;
      font-size: 18px;
      text-align: center; }
    #homePage #textAndImages #sidebar {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      #homePage #textAndImages #sidebar .imgContainer {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column; }
        #homePage #textAndImages #sidebar .imgContainer img {
          margin: 0 auto 5px; }
      #homePage #textAndImages #sidebar #encouraged,
      #homePage #textAndImages #sidebar #endorsed {
        margin-top: 20px; }
    #homePage #textAndImages #homeText {
      text-align: justify; }

@media screen and (min-width: 901px) {
  #homePage #textAndImages {
    -webkit-flex-direction: row;
            flex-direction: row; }
    #homePage #textAndImages #homeText {
      width: 80%; }
    #homePage #textAndImages #sidebar {
      width: 25%; }
      #homePage #textAndImages #sidebar h4 {
        margin-left: 20%; }
      #homePage #textAndImages #sidebar .imgContainer {
        margin-left: 20%; }
        #homePage #textAndImages #sidebar .imgContainer img {
          width: 90%; } }

@media screen and (max-width: 900px) {
  #homePage #textAndImages {
    -webkit-flex-direction: column;
            flex-direction: column; }
    #homePage #textAndImages #sidebar {
      width: 100%; }
      #homePage #textAndImages #sidebar h4 {
        width: 70%;
        margin-right: auto;
        margin-left: auto; }
      #homePage #textAndImages #sidebar .imgContainer img {
        width: 40%; } }

#slideshow .legend {
  opacity: 1;
  background-color: #e0dede;
  color: black;
  font-size: 18px;
  width: 47%;
  bottom: -10px; }
  #slideshow .legend a {
    color: grey !important;
    text-decoration: none; }

#slideshow .carousel {
  text-align: center; }

@media screen and (max-width: 499px) {
  #slideshow {
    display: none; } }

#diseasesPage #searchContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 20px; }
  #diseasesPage #searchContainer #text {
    font-size: 20px;
    text-align: center; }
  #diseasesPage #searchContainer #searchField {
    margin-left: 2%;
    z-index: 2; }
    #diseasesPage #searchContainer #searchField input {
      width: 200px; }

#genesPage #searchContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 20px;
  text-align: center; }
  #genesPage #searchContainer .searchArea {
    width: 40%; }
    #genesPage #searchContainer .searchArea .text {
      font-size: 20px;
      text-align: center; }
    #genesPage #searchContainer .searchArea .searchField {
      position: relative;
      margin-left: 2%;
      z-index: 2;
      text-align: left; }
      #genesPage #searchContainer .searchArea .searchField input {
        width: 150px; }

@media screen and (min-width: 901px) {
  #pathogenicPage {
    width: 80%; } }

@media screen and (min-width: 901px) {
  #pharmacogenomicPage {
    width: 80%; } }

@media screen and (min-width: 901px) {
  #medicinePage {
    width: 80%; } }

@media screen and (max-width: 900px) {
  #europeanPage #supported h4,
  #europeanPage #encouraged h4,
  #europeanPage #endorsed h4 {
    margin-top: 20px; }
  #europeanPage #supported img,
  #europeanPage #encouraged img,
  #europeanPage #endorsed img {
    width: 150px; } }

@media screen and (min-width: 901px) {
  #europeanPage {
    width: 80%; }
    #europeanPage #supported h4,
    #europeanPage #encouraged h4,
    #europeanPage #endorsed h4 {
      margin-top: 20px !important; }
    #europeanPage #supported img,
    #europeanPage #encouraged img,
    #europeanPage #endorsed img {
      width: 240px;
      margin: 10px 25px; } }

#contactPage {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  #contactPage #details h4 {
    font-weight: normal;
    margin-top: 20px; }
  #contactPage #details .details {
    margin-bottom: 10px; }
  #contactPage #details #location {
    margin: 2% 0;
    font-size: 18px; }
  #contactPage #mapContainer {
    height: 46vh; }
    #contactPage #mapContainer .map {
      width: 59% !important;
      height: 45% !important; }

@media screen and (min-width: 901px) and (max-width: 1211px) {
  #contactPage #mapContainer .map {
    width: 73% !important; } }

@media screen and (max-width: 900px) {
  #contactPage #mapContainer .map {
    width: 88% !important; } }

#researchersPage #searchContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 20px; }
  #researchersPage #searchContainer #text {
    font-size: 20px;
    text-align: center; }
  #researchersPage #searchContainer #searchField {
    margin-left: 2%;
    z-index: 2; }
    #researchersPage #searchContainer #searchField input {
      width: 200px; }

#mutationPage {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  #mutationPage .searchContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: 20px; }
    #mutationPage .searchContainer.blurSearch {
      -webkit-animation-name: showSlideBar;
              animation-name: showSlideBar;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
    #mutationPage .searchContainer .text {
      font-size: 20px;
      text-align: center; }
    #mutationPage .searchContainer .searchField {
      margin-left: 2%;
      z-index: 2; }
      #mutationPage .searchContainer .searchField input {
        width: 200px; }
  #mutationPage #sliderSearch {
    margin-top: 5%; }
    #mutationPage #sliderSearch.searchContainer {
      opacity: 0.3; }
  #mutationPage .searchButton {
    background-color: lightgray;
    opacity: 0.3;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    width: 100px;
    height: 40px;
    outline: none;
    color: #ececec;
    background-color: #555;
    background: -webkit-linear-gradient(#888, #555);
    background: linear-gradient(#888, #555);
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0 1px 0 #666, 0 5px 0 #444, 0 6px 6px rgba(0, 0, 0, 0.6);
    margin: 3% 5% 0 0; }
    #mutationPage .searchButton.blurSearch {
      -webkit-animation-name: showSlideBar;
              animation-name: showSlideBar;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
    #mutationPage .searchButton:active {
      color: #fff;
      text-shadow: 0 -1px 0 #444, 0 0 5px #ffd, 0 0 8px #fff;
      box-shadow: 0 1px 0 #666, 0 2px 0 #444, 0 2px 2px rgba(0, 0, 0, 0.9);
      -webkit-transform: translateY(3px);
      transform: translateY(3px); }

@-webkit-keyframes showSlideBar {
  0% {
    opacity: 0.3; }
  100% {
    background-color: white;
    opacity: 1; } }

@keyframes showSlideBar {
  0% {
    opacity: 0.3; }
  100% {
    background-color: white;
    opacity: 1; } }

.searchContainer > .blurSearch {
  background-color: green !important; }

.twoDotSlider {
  width: 70% !important;
  margin: 40px auto; }

.higherDot {
  background-color: blue; }

.inputs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 70%;
  margin: 0 auto; }
  .inputs .showValue {
    font-size: 18px; }
    .inputs .showValue .label {
      padding: 5px 10px;
      background: #7ab893;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px; }
    .inputs .showValue .value {
      padding: 5px 10px;
      background: #a6e1e2;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      margin-left: -5px; }

@media screen and (max-width: 800px) {
  .twoDotSlider {
    width: 100% !important; }
  .inputs {
    width: 100%; } }

.signUpPage h1 {
  margin-bottom: 15px !important; }

.signUpPage form {
  width: 400px;
  margin: 0 0 0 2%; }
  .signUpPage form .labelInput {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 5% 0; }
    .signUpPage form .labelInput input {
      background-color: transparent;
      border-radius: 0;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      font-family: inherit;
      font-size: 1em;
      border-bottom: 1px solid #250ef1;
      outline: none;
      width: 190px; }
    .signUpPage form .labelInput #pass,
    .signUpPage form .labelInput #retypePass {
      width: 160px !important; }
    .signUpPage form .labelInput .eyeIcon {
      background-color: #CCCCFF;
      cursor: pointer;
      padding: 1px;
      border: 1px solid grey;
      border-radius: 25%; }
  .signUpPage form #errormsg {
    background-color: #f0bfbf;
    padding: 0 10px;
    font-size: 20px; }
  .signUpPage form .submitbtn {
    cursor: pointer;
    width: 200px;
    height: 50px;
    outline: none;
    background-color: #CCCCFF;
    border: 0 none;
    border-radius: 10px;
    margin: 10% auto;
    text-align: center;
    padding: 10px 0;
    font-size: 20px; }

#loginPage {
  text-align: center;
  width: 300px;
  background-color: #CCCCFF;
  padding: 5px 15px 30px;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  #loginPage .topLine {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    #loginPage .topLine .topText {
      font-size: 20px;
      padding: 15px; }
    #loginPage .topLine .close {
      margin-bottom: 20px;
      font-size: 25px;
      font-weight: bold;
      padding: 10px;
      background-color: #250ef1;
      border: 0 none;
      border-radius: 1px; }
  #loginPage .description {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    #loginPage .description .descLine {
      margin: 5px 0; }
  #loginPage .loginInputs {
    margin: 20px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    #loginPage .loginInputs input {
      background-color: transparent;
      border-radius: 0;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      font-family: inherit;
      font-size: 1em;
      border-bottom: 1px solid #250ef1;
      outline: none;
      width: 200px;
      margin: 15px auto; }
    #loginPage .loginInputs #passEye {
      width: 170px !important; }
    #loginPage .loginInputs .eyeIcon {
      background-color: white;
      cursor: pointer;
      padding: 3px;
      border: 1px solid grey;
      border-radius: 25%; }
  #loginPage .loginButton {
    cursor: pointer;
    width: 200px;
    height: 50px;
    outline: none;
    background-color: #250ef1;
    border: 0 none;
    border-radius: 10px;
    margin: 5% auto;
    text-align: center;
    padding: 10px 0;
    font-size: 20px;
    color: white; }
  #loginPage #errormsg {
    background-color: #f0bfbf;
    padding: 0 10px;
    font-size: 20px; }

