@import "./../../../global-styles.scss";

#homePage {
  display: flex;
  flex-direction: column;

  #textAndImages {
    display: flex;

    h4 {
      background-color: $main-color;
      color: white;
      font-size: 18px;
      text-align: center;
    }

    #sidebar {
      display: flex;
      flex-direction: column;

      .imgContainer {
        display: flex;
        flex-direction: column;

        img {
          margin: 0 auto 5px;
        }
      }

      #encouraged,
      #endorsed {
        margin-top: 20px;
      }
    }

    #homeText {
      text-align: justify;
    }
  }
}

@media screen and (min-width: 901px) {
  #homePage {
    #textAndImages {
      flex-direction: row;

      #homeText {
        width: $text-page-width;
      }

      #sidebar {
        width: 25%;

        h4 {
          margin-left: 20%;
        }

        .imgContainer {
          margin-left: 20%;

          img {
            width: 90%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  #homePage {
    #textAndImages {
      flex-direction: column;

      #sidebar {
        width: 100%;

        h4 {
          width: 70%;
          margin-right: auto;
          margin-left: auto;
        }

        .imgContainer {
          img {
            width: 40%;
          }
        }
      }
    }
  }
}
