.twoDotSlider {
  width: 70% !important;
  margin: 40px auto;
}

.higherDot {
  background-color: blue;
}

.inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;

  .showValue {
    font-size: 18px;

    .label {
      padding: 5px 10px;
      background: #7ab893;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    .value {
      padding: 5px 10px;
      background: #a6e1e2;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      margin-left: -5px;
    }
  }
}

@media screen and (max-width: 800px) {
  .twoDotSlider {
    width: 100% !important;
  }
  .inputs {
    width: 100%;
  }
}
