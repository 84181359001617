#diseasesPage {
  #searchContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    #text {
      font-size: 20px;
      text-align: center;
    }

    #searchField {
      margin-left: 2%;
      z-index: 2;

      input {
        width: 200px;
      }
    }
  }
}
