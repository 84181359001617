@import "./../../../global-styles.scss";
@media screen and (max-width: 900px) {
  #europeanPage {
    #supported,
    #encouraged,
    #endorsed {
      h4 {
        margin-top: 20px;
      }
      img {
        width: 150px;
      }
    }
  }
}

@media screen and (min-width: 901px) {
  #europeanPage {
    width: $text-page-width;

    #supported,
    #encouraged,
    #endorsed {
      h4 {
        margin-top: 20px !important;
      }
      img {
        width: 240px;
        margin: 10px 25px;
      }
    }
  }
}
