@import "../../../../global-styles.scss";

@media screen and (min-width: 701px) {
  #headerContainer {
    margin: 10px auto;

    #imageLogin {
      padding-left: 5%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      img {
        width: 45%;
        padding: 0 0 1% 0;
      }
      @media screen and (max-width: 1025px) {
        img {
          width: 75%;
        }
      }

      .login {
        min-width: 200px;
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: column;

        .loginLink,
        .signupLink {
          cursor: pointer;
          outline: none;
          background-color: $main-background-color-top;
          border: 0 none;
          border-radius: 10px;
          text-align: center;
          color: white;
          padding: 1p% 0;
          font-size: 20px;
          text-decoration: none;
        }
      }
    }

    #topMenuBar {
      display: flex;
      justify-content: space-around;
      background-color: $main-background-color-top;

      .iconLink {
        img {
          width: 30px;
          margin: 5px auto 0;
          display: flex;
          flex-direction: column;
        }
      }

      .link {
        position: relative;
        display: inline-block;

        & * {
          outline: none;
          border: none;
          color: white;
          font-size: 17px;
          cursor: pointer;
          text-decoration: none;
        }

        &:hover {
          .filtersDropdown {
            display: flex;
            z-index: 3;
          }
        }

        .filtersDropdown {
          display: none;
          flex-direction: column;
          position: absolute;
          text-align: start;
          border: 0.1px solid grey;
          border-radius: 0 0 0.5em 0.5em;

          & :hover {
            background-color: rgb(32, 41, 49);
          }

          .lastLink {
            border-radius: 0 0 0.5em 0.5em;
          }

          & * {
            padding: 5px;
            background-color: grey;
            font-size: 14px;
          }
        }

        #documentation {
          left: -30%;
          width: 202px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  #headerContainer {
    display: none;
  }
}
