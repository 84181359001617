@import "./global-styles";

body {
  font-family: $main-text-font !important;
  margin: -10px 0 !important;
}

#mainPage {
  margin: 0 auto;
  background-color: white;

  #loginContainer {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation-name: slide-login;
    animation-duration: 0.7s;
  }

  .loginOn {
    opacity: 0.3;
    pointer-events: none;
  }

  .mainBody {
    padding: 0 1%;
  }

  h1 {
    color: $main-color;
    font-weight: normal;
    margin: 1% 0;
    font-size: 24px;
  }

  #footer {
    text-align: center;
  }
}

@media screen and (min-width: 1211px) {
  #mainPage {
    width: 75%;
  }
}

@media screen and (min-width: 901px) and (max-width: 1211px) {
  #mainPage {
    width: 85%;
  }
}

@media screen and (max-width: 900px) {
  #mainPage {
    width: 99%;
  }
}

@media screen and (min-width: 741px) {
  #mainPage {
    #headerBody {
      min-height: calc(100vh - 68px);
    }
  }
}

@media screen and (max-width: 740px) {
  #mainPage {
    #headerBody {
      min-height: calc(100vh - 78px);
    }
  }
}

@keyframes slide-login {
  0% {
    left: 0%;
  }
  100% {
    left: 50%;
  }
}
