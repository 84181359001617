@import "./../../../global-styles";

#mapPage {
  #mapContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .rsm-svg {
      width: 80%;
      background-color: rgb(195, 210, 250);
      .rsm-geography {
        outline: none;
      }
    }

    #zoomButtons {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      button {
        background-color: $main-color;
        border: 1px solid $main-color;
        color: white;
        outline: none;
        margin: 5px;
        cursor: pointer;
        width: 54px;
        height: 54px;
      }

      .resetButton {
        border-radius: 30%;
        font-size: 14px;
      }

      .zoom {
        border-radius: 50%;
        font-size: 25px;
      }
    }
  }

  h2 {
    text-align: center;
  }

  h4 {
    margin-top: 10px;
    text-align: center;
  }
}
