@import "./../../../global-styles";

#loginPage {
  text-align: center;
  width: 300px;
  background-color: $main-background-color-top;
  padding: 5px 15px 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .topLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .topText {
      font-size: 20px;
      padding: 15px;
    }

    .close {
      margin-bottom: 20px;
      font-size: 25px;
      font-weight: bold;
      padding: 10px;
      background-color: $main-color;
      border: 0 none;
      border-radius: 1px;
    }
  }

  .description {
    display: flex;
    flex-direction: column;

    .descLine {
      margin: 5px 0;
    }
  }
  .loginInputs {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    input {
      background-color: transparent;
      border-radius: 0;
      border: none;

      -webkit-appearance: none;
      -moz-appearance: none;

      font-family: inherit;
      font-size: 1em;
      border-bottom: 1px solid $main-color;
      outline: none;
      width: 200px;
      margin: 15px auto;
    }

    #passEye {
      width: 170px !important;
    }

    .eyeIcon {
      background-color: white;
      cursor: pointer;
      padding: 3px;
      border: 1px solid grey;
      border-radius: 25%;
    }
  }

  .loginButton {
    cursor: pointer;
    width: 200px;
    height: 50px;
    outline: none;
    background-color: $main-color;
    border: 0 none;
    border-radius: 10px;
    margin: 5% auto;
    text-align: center;
    padding: 10px 0;
    font-size: 20px;
    color: white;
  }

  #errormsg {
    background-color: rgb(240, 191, 191);
    padding: 0 10px;
    font-size: 20px;
  }
}
