#genesPage {
  #searchContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    text-align: center;

    .searchArea {
      width: 40%;

      .text {
        font-size: 20px;
        text-align: center;
      }

      .searchField {
        position: relative;
        margin-left: 2%;
        z-index: 2;
        text-align: left;

        input {
          width: 150px;
        }
      }
    }
  }
}
