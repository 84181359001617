@import "../../../../global-styles.scss";

@media screen and (max-width: 700px) {
  .mobileHeaderContainer {
    margin: 10px auto;

    #logoAndButton {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 5px;

      #imageContainer {
        width: 100%;

        img {
          width: 80%;
        }
      }

      #hamburger {
        background-color: $main-color;
        outline: none;
        border: none;
        width: 45px;
        height: 45px;
        border-radius: 0.5em;
        align-self: flex-end;
        cursor: pointer;
        margin: auto;

        .burger {
          color: white;
          font-size: 24px;
        }
      }
    }

    .mobileLogin {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 10px 0;

      .mobileLoginLink {
        cursor: pointer;
        width: 100px;
        height: 38px;
        outline: none;
        background-color: $main-color;
        border: 0 none;
        border-radius: 10px;
        text-align: center;
        color: white;
        padding: 5px 0;
        font-size: 20px;
      }
    }

    #mobileMenu {
      display: flex;
      flex-direction: column;
      text-align: center;

      img {
        width: 20px;
        margin: 0 10px 0 -10px;
        padding: 0;
      }
      .menuOption {
        background-color: $main-background-color-top;
        cursor: pointer;

        .moreOptions {
          display: flex;
          flex-direction: column;
          background-color: grey;
        }
        &#removePadding {
          padding-bottom: 0;
        }
      }

      & * {
        text-decoration: none;
        color: white;
        font-size: 24px;
      }
      #homeLink,
      #documentationLink,
      #mapLink,
      #diseasesLink,
      #genesLink,
      #frequencyLink,
      #researchersLink {
        padding: 2% 0;
      }
    }
  }
}

@media screen and (min-width: 701px) {
  .mobileHeaderContainer {
    display: none;
  }
}
