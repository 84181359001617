@import "./../../../global-styles";

#footerContainer {
  /* If you want it in the center, put again the same widths as
  the main page and put text align
  width: 60%;
  text-align: center;
  */

  #links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    & * {
      text-decoration: none;
      color: $main-color;
    }
  }

  #copyright {
    margin-top: 5px;
    font-size: 12px;
    color: grey;
  }
}
