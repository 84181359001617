#slideshow {
  .legend {
    opacity: 1;
    background-color: rgb(224, 222, 222);
    color: black;
    font-size: 18px;
    width: 47%;
    bottom: -10px;
    a {
      color: grey !important;
      text-decoration: none;
    }
  }

  .carousel {
    text-align: center;
  }
}

@media screen and (max-width: 499px) {
  #slideshow {
    display: none;
  }
}
