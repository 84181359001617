#mutationPage {
  display: flex;
  flex-direction: column;

  .searchContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    &.blurSearch {
      animation-name: showSlideBar;
      animation-duration: 2s;
      animation-fill-mode: forwards;
    }

    .text {
      font-size: 20px;
      text-align: center;
    }

    .searchField {
      margin-left: 2%;
      z-index: 2;

      input {
        width: 200px;
      }
    }
  }

  #sliderSearch {
    margin-top: 5%;

    &.searchContainer {
      opacity: 0.3;
    }
  }

  .searchButton {
    background-color: lightgray;
    opacity: 0.3;
    align-self: flex-end;
    width: 100px;
    height: 40px;
    outline: none;
    color: rgb(236, 236, 236);
    background-color: #555;
    background: linear-gradient(#888, #555);
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0 1px 0 #666, 0 5px 0 #444, 0 6px 6px rgba(0, 0, 0, 0.6);
    margin: 3% 5% 0 0;

    &.blurSearch {
      animation-name: showSlideBar;
      animation-duration: 2s;
      animation-fill-mode: forwards;
    }

    &:active {
      color: #fff;
      text-shadow: 0 -1px 0 #444, 0 0 5px #ffd, 0 0 8px #fff;
      box-shadow: 0 1px 0 #666, 0 2px 0 #444, 0 2px 2px rgba(0, 0, 0, 0.9);
      -webkit-transform: translateY(3px);
      transform: translateY(3px);
    }
  }
}

@keyframes showSlideBar {
  0% {
    opacity: 0.3;
  }
  100% {
    background-color: white;
    opacity: 1;
  }
}
.searchContainer > .blurSearch {
  background-color: green !important;
}
