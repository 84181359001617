@import "./../../../global-styles";

.signUpPage {
  h1 {
    margin-bottom: 15px !important;
  }

  form {
    width: 400px;
    margin: 0 0 0 2%;
    .labelInput {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 5% 0;

      input {
        background-color: transparent;
        border-radius: 0;
        border: none;

        -webkit-appearance: none;
        -moz-appearance: none;

        font-family: inherit;
        font-size: 1em;
        border-bottom: 1px solid $main-color;
        outline: none;
        width: 190px;
      }

      #pass,
      #retypePass {
        width: 160px !important;
      }

      .eyeIcon {
        background-color: $main-background-color-top;
        cursor: pointer;
        padding: 1px;
        border: 1px solid grey;
        border-radius: 25%;
      }
    }

    #errormsg {
      background-color: rgb(240, 191, 191);
      padding: 0 10px;
      font-size: 20px;
    }

    .submitbtn {
      cursor: pointer;
      width: 200px;
      height: 50px;
      outline: none;
      background-color: $main-background-color-top;
      border: 0 none;
      border-radius: 10px;
      margin: 10% auto;
      text-align: center;
      padding: 10px 0;
      font-size: 20px;
    }
  }
}
