@import "./../../../global-styles";

#contactPage {
  display: flex;
  flex-direction: column;

  #details {
    h4 {
      font-weight: normal;
      margin-top: 20px;
    }

    .details {
      margin-bottom: 10px;
    }

    #location {
      margin: 2% 0;
      font-size: 18px;
    }
  }

  #mapContainer {
    height: 46vh;

    .map {
      width: 59% !important;
      height: 45% !important;
    }
  }
}

@media screen and (min-width: 901px) and (max-width: 1211px) {
  #contactPage {
    #mapContainer {
      .map {
        width: 73% !important;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  #contactPage {
    #mapContainer {
      .map {
        width: 88% !important;
      }
    }
  }
}
